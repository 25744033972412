import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player"

import Menu from "../components/Menu"
import Divider from "../components/Divider"
import Footer from "../components/Footer"

import Seo from "../components/seo"

export default function IndexPage() {
  return (
    <div className="bg-white">
      <Seo title="Cahoot Digital Experience Platform" />
      <main>
        <Menu />
        {/* Hero section */}
        <div className="sm:my-8 lg:mb-0 md:pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-18 flex content-center">
          <div className="pb-10 md:pb-0 mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl ">
            <div>
              <div className="hidden md:block">
                <ReactPlayer url="https://vimeo.com/701415039" width="750px" />
              </div>
              <div className="md:hidden">
                <ReactPlayer url="https://vimeo.com/701415039" width="350px" />
              </div>

              <div className="md:mt-12 lg:mt-16">
                <div className="-mt-8 md:mt-6 sm:max-w-2xl">
                  <h1 className="leading-relaxed text-2xl lg:leading-normal lg:text-3xl font-extrabold text-cahoot-blue-med font-header text-center">
                    Stop wasting time. Start communicating.
                  </h1>
                  <p className="mt-10 lg:text-xl text-cahoot-blue-dark font-text tracking-tight text-center leading-normal">
                    We're a digital experience platform designed to streamline communication and help you reach the largest audience possible. Simplify how you aggregate and manage information with our "create once, publish everywhere" design.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="-mt-16">
          <Divider color='darkBlue1' />
        </div>

        {/* Content management section */}
        <div className="relative bg-cahoot-blue-dark">
          <div className="text-center sm:text-left lg:mx-auto lg:max-w-7xl lg:px-8 md:grid md:grid-cols-2 lg:gap-24 md:items-start">
            <div className="relative sm:py-16 md:py-0">
              {/* Small: Content area */}
              <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 md:hidden">
                <div className="pt-12 sm:pt-16">
                  <h2 className="text-2xl font-header text-cahoot-blue-light font-extrabold tracking-tight">
                    Easy to use content management
                  </h2>
                  <div className="mt-6 text-white space-y-6 font-text">
                    <p className="text-base">
                      <span className="font-header">Cahoot</span> is enterprise-grade software built to make the job
                      of managing content simple for organizations. With <span className="font-header">Cahoot</span>,
                      you can manage your content in a single place without the
                      need to juggle multiple apps.
                    </p>
                  </div>
                </div>
              </div>
              {/* Image for both */}
              <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:p-4">
                <div className="relative h-64 lg:h-96 lg:mb-16 rounded-2xl">
                  <div className="absolute w-full">
                    <StaticImage src="../images/women-at-computer.jpg" imgStyle={{ borderRadius: '1rem' }} alt="Women working at a computer" className="h-48 lg:h-96 rounded-2xl shadow-xl" />
                  </div>
                </div>
              </div>
            </div>

            {/* Medium +:Content area */}
            <div className="hidden md:block relative mx-auto max-w-md px-4 lg:px-0">
              <div className="lg:pt-12">
                <h2 className="text-2xl lg:text-3xl font-header text-cahoot-blue-light font-extrabold tracking-tight">
                  Easy to use content management
                </h2>
                <div className="lg:mt-6 text-white font-text space-y-6 lg:leading-normal">
                  <p className="lg:text-lg">
                    <span className="font-header">Cahoot</span> is enterprise-grade software built to make the job of
                    managing content simple for organizations. With <span className="font-header">Cahoot</span>, you
                    can manage your content in a single place without the need
                    to juggle multiple apps.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Professional website section */}
        <div className="text-center md:text-left py-12 md:pt-16 md:pb-0 overflow-hidden lg:relative lg:py-24">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl md:grid md:grid-cols-2 lg:gap-24">
            <div>
              {/* <div> */}
              <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                {/* Content area */}
                <div className="lg:pt-20">
                  <h2 className="text-2xl lg:text-3xl text-cahoot-blue-dark font-extrabold tracking-tight font-header">
                    Professionally designed website
                  </h2>
                  <div className="mt-6 text-cahoot-blue-dark font-text space-y-6">
                    <p className="text-base lg:text-lg">
                      <span className="font-header">Cahoot</span> includes a pre-designed website that adheres to
                      best practices for search engine optimization.
                      Information from Cahoot automatically appears on your
                      website.
                    </p>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className="relative">
              <div className="relative h-48 lg:h-72">
                <div className="absolute w-full">
                  <StaticImage src="../images/designer.jpg" imgStyle={{ borderRadius: '1rem' }} alt="Cahoot admin screenshot" className="h-48 lg:h-96 rounded-2xl shadow-xl" />
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* Divider */}
        <div className="sm:-mb-8">
          <Divider color='lightBlue1' />
        </div>

        {/* Publish content one time */}
        <div className="relative text-center md:text-left bg-cahoot-blue-light pb-12 md:pt-16 md:pb-0 overflow-hidden lg:relative lg:py-24">
          <div className="lg:mx-auto lg:max-w-7xl px-4 lg:px-8 md:grid md:grid-cols-2 lg:gap-24 md:items-start">
            {/* Small:Content area */}
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 md:hidden">
              <div className="pt-12 sm:pt-16">
                <h2 className="text-2xl sm:text-4xl text-cahoot-blue-dark font-header font-extrabold tracking-tight" >
                  Publish content one time
                </h2>
                <div className="mt-6 text-cahoot-blue-dark font-text space-y-6">
                  <p className="text-base">
                    Stop spending time manually updating social media.
                    With <span className="font-header">Cahoot</span>, you can automatically publish content
                    to Facebook with ease. Links back
                    to your website are automatically added so that people can
                    learn more.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative sm:pb-16 lg:py-0">
              {/* <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:p-4"> */}
              <div className="relative h-52 lg:h-72 lg:mb-16">
                <div className="absolute w-full">
                  <StaticImage src="../images/facebook_image.jpg" imgStyle={{ borderRadius: '1rem' }} alt="Mobile phone showing Facebook" className="h-52 lg:h-96 rounded-2xl shadow-xl" />
                </div>
              </div>
              {/* </div> */}
            </div>

            {/* Medium +: Content area */}
            <div className="hidden md:block relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
              <div className="lg:pt-20">
                <h2 className="text-2xl lg:text-3xl text-cahoot-blue-dark font-header font-extrabold tracking-tight">
                  Publish content one time
                </h2>
                <div className="mt-6 text-cahoot-blue-dark font-text space-y-6">
                  <p className="lg:text-lg">
                    Stop spending time manually updating social media.
                    With <span className="font-header">Cahoot</span>, you can automatically publish content
                    to Facebook with ease. Links back
                    to your website are automatically added so that people can
                    learn more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CTA section */}
        <div className="py-16 bg-cahoot-white">
          <div className="mx-auto px-4 sm:px-6 lg:px-24">
            <div className="rounded-md px-6 py-10 bg-white overflow-hidden shadow-xl sm:px-12 sm:py-10">
              <div className="lg:flex gap-x-24">
                <div className="text-center md:text-left lg:w-1/2 bg-blue">
                  <h2 className="text-3xl font-extrabold text-cahoot-blue-med font-header tracking-tight sm:text-4xl">
                    Stay up to date!
                  </h2>
                  <p className="mt-6 mx-auto max-w-2xl text-lg text-cahoot-blue-dark font-text font-bold">
                    Join our mailing list for the latest information about <span className="font-header">Cahoot</span>!
                  </p>
                  <p className="font-text text-cahoot-blue-dark">
                    By joining our mailing list you will receive emails with news and details including latest updates and announcements. We will also offer tips on how your organization can improve its communication strategies.
                  </p>
                  <p className="font-text text-cahoot-blue-dark">Let's <span className="font-header">Cahoot</span>!</p>
                 <div className="flex items-center justify-end text-cahoot-blue-dark font-text text-xs mt-24">
                        <span>By submitting this form, you are consenting to receive marketing emails from: Apotheosis Technologies LLC, https://apotheosistech.com. You can revoke your consent to receive emails at any time by using the unsubscribe link, found at the bottom of every email. </span>
                      </div>
                </div>
                <div className="lg:w-1/2">
                  <iframe width="540" height="495" src="https://b876a6d0.sibforms.com/serve/MUIEALXUFdTNGe7C3YocrVxZqZVmP9ngCWruk_rqcAE2t1XdN_A_QI5gGS8CY-S6nL23Ow1sWGOA1blwb5cy44caKWOcHFuMTEbb_lqdoB6yuGG_yF-MkubRA7VsiaRo4j2froc2QYn-usaI7VgMMuRySR-pFnWYdu_B-kqa8opKqVB1kAaHYjsrD9lT5yTmwpzVBywls62B0g0J" frameborder="0" scrolling="no" allowfullscreen className="container mx-auto"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Footer section */}
      <Footer />
    </div>
  )
}
